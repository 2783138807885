import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("./views/Home.vue")
  },
  {
    path: "/tos",
    name: "Tos",
    component: () => import(/* webpackChunkName: "legal" */ "./views/Tos.vue")
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () =>
      import(/* webpackChunkName: "legal" */ "./views/Privacy.vue")
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash
      };
    }
    return { top: 0 };
  }
});

export default router;
