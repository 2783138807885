<template>
  <footer id="Contact">
    <div class="container mb-5 pt-5">
      <div class="row">
        <div class="col-lg-3 col-md-4">
          <img class="mb-3 logo" src="../assets/images/logo.png" />
          <p class="text-secondary">
            LucidIntel is an advanced AI and Machine learning data service
            provider that will help you greatly advance key metrics of your
            business.
          </p>
        </div>
        <div class="col-lg-3 col-md-4">
          <h4 class="mb-3">Contact</h4>
          <ul>
            <li>
              <a href="mailto:Hello@lucidintel.com">
                <i class="fa fa-envelope"></i>
                <span class="ml-2">Hello@lucidintel.com</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-4 ml-auto">
          <div class="row">
            <div class="col-12">
              <router-link to="/tos" custom v-slot="{ navigate }">
                <a class="nav-link d-block" @click="navigate">Term of use</a>
              </router-link>
              <router-link to="/privacy" custom v-slot="{ navigate }">
                <a class="nav-link d-block" @click="navigate">Privacy Policy</a>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-dark py-3">
      <div class="col-lg-12 text-secondary text-center">
        <span>
          <a target="_blank" href="#" class="text-decoration-none text-white">
            Copyright {{ new Date().getFullYear() }} LucidIntel All Rights Reserved.
          </a>
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped lang="scss">
@import "./../assets/css/variables.scss";

.logo {
  width: 100%;
  max-width: 250px;
}

ul {
  padding: 0;

  li {
    list-style: none;
    margin: 0 0 10px 0;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      outline: none;
      text-decoration: none;
    }

    i {
      font-size: 20px;
      line-height: 2;
      color: $primary;
    }
  }
}
</style>
