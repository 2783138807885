<template>
  <header class="container justify-content-md-center">
    <nav class="navbar navbar-expand-lg navbar-light">
      <a class="navbar-brand" href="#">
        <img src="../assets/images/logo.png" alt loading="lazy" class="logo" />
      </a>

      <div
        class="collapse navbar-collapse justify-content-end"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link to="/" custom v-slot="{ navigate }">
              <a class="nav-link" aria-current="page" @click="navigate">Home</a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/#AboutUs" custom v-slot="{ navigate }">
              <a class="nav-link" @click="navigate">About Us</a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/#Contact" custom v-slot="{ navigate }">
              <a class="nav-link" @click="navigate">Contact</a>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Header"
};
</script>

<style scoped lang="scss">
@import "./../assets/css/variables.scss";

@media (min-width: 1301px) {
  .container {
    max-width: 1300px;
  }
}

.logo {
  height: 55px;
}
</style>
